// Includes
import Home from './home';
import Courses from './courses';
import Tutorials from './tutorials';
import Contact from './contact';
import Gallery from './gallery';
import NotFound from './common/notfound';
import Promoted from './courses/la-fete-du-cirque';
import Promoted2 from './courses/workshop-fine-art';
import RetratoInfantil from './courses/retrato-infantil';

const routes = [
    {
        path: "/contact",
        component: Contact
    },
    {
        path: "/gallery",
        component: Gallery
    },
    {
        path: "/tutorials",
        component: Tutorials
    },
    {
        path: "/courses/retrato-infantil",
        component: RetratoInfantil
    },
    {
        path: "/courses/workshop-fine-art",
        component: Promoted2
    },
    {
        path: "/courses/la-fete-du-cirque",
        component: Promoted
    },
    {
        path: "/courses",
        component: Courses
    },
    {
        path: "/",
        component: Home
    },
    {
        path: "*",
        component: NotFound
    }
];

export default routes;