import React from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';

const ModalItem = ({ title, show, onHide, paypal, mxn, usd }) => {
    return(
        <Modal show={show}
            onHide={onHide}
            centered
            size="lg"
            dialogClassName="modal-100w">
            <Modal.Header closeButton><b>{title}: Opciones de Pago</b></Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                    Para hacer el pago de este producto puedes usar el botón "Comprar Ahora" de PayPal 
                    o bien, si gustas hacer una transferencia interbancaria los datos de nuestro banco se 
                    encuentran abajo.<br/><br/>
                    </Row>
                    <Row className="text-center">
                        { paypal ? <Col sm={6} className="paypal-form"><p><b>Pago con tarjeta con PayPal</b></p><div dangerouslySetInnerHTML={{ __html: paypal }}></div></Col> : '' }
                        <Col sm={paypal ? 6 : 12}>
                            <b>Pago con transferencia electrónico</b><br/>
                            <b>BBVA Bancomer</b><br/>
                            <b>Tarjeta:</b> 4152 3134 6035 0965<br/>
                            <b>CLABE:</b> 01 27 600 293 2777 8861<br/>
                            <b>Cuenta:</b> 293 2777 886<br/>
                            <b>Nombre:</b> Nathalia Gutierrez Zamora Patiño<br/>
                            <b>Concepto:</b> {title}<br/>
                            {mxn > 0 ? <span><b>Monto MXN: ${mxn} MXN</b><br/></span> : '' }
                            {usd > 0 ? <span><b>Monto USD: ${usd} USD</b><br/></span> : '' }
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <em>*En caso de hacer transferencia electrónica, una vez hecho el pago envía un correo con fotografía del comprobante a <a href="mailto:ventas@nathaliagutierrez.com.mx">ventas@nathaliagutierrez.com.mx</a> y en breve te enviaremos el link de descarga.</em>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onHide}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalItem;