import { React, Component } from 'react';
import Gallery from './gallery';
import SimpleReactLightbox from "simple-react-lightbox";
import WOW from 'wowjs';

import './gallery.scss';

class GalleryWrapper extends Component {
    componentDidMount() {
        new WOW.WOW({live: false}).init();
    } 
    
    render() {
        return(
            <SimpleReactLightbox>
                <Gallery />
            </SimpleReactLightbox>
        );
    }
}

export default GalleryWrapper;