import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faClock } from '@fortawesome/free-solid-svg-icons';
import ModalItem from '../modal';
import parse from 'html-react-parser';

const Item = ({identifier, title, mxn, usd, type, sub_type, image = '', includes, duration, showing, description, paypal}) => {
    const variants = {
        tutorial: 'success',
        action: 'primary',
        'digital-resource': 'danger',
        'on-site': 'warning',
        recorded: 'primary',
        online: 'danger'
    }

    const variants_name = {
        tutorial: 'Tutoriales',
        'digital-resource': 'Recursos Digitales',
        action: 'Actions',
        'on-site': 'Presencial',
        recorded: 'Pre-grabado',
        online: 'En línea'
    }

    const variant = variants[sub_type];
    const [modalShow, setModalShow] = useState(false);
    const [currentPhoto, setCurrentPhoto] = useState('');

    useEffect(() => {
        if(image) setCurrentPhoto(image[0].image);
    }, [image]);

    const ItemThumbs = (images) => {
        return images.map((img, index) => <img src={`${img.thumbnail}`} alt={title} key={index} onClick={() => setCurrentPhoto(`${img.image}`)} />);
    }

    const Price = () => {
        console.log(mxn, usd);
        const priceMXN = mxn > 0 ? `$${mxn} MXN` : false;
        const priceUSD = usd > 0 ? `$${usd} USD` : false;
        console.log(priceMXN, priceUSD);
        return(
            <span className="item-price">
                {priceMXN}{priceMXN && priceUSD ? ' / ' : ''}{priceUSD}
            </span>
        );
    }

    return(
        <Row key={title} id={`#${identifier}`} className={ showing === 'all' || showing === sub_type ? "courses show" : "courses"}>
            <Col>
                <Row>
                    <Col sm="6" className="item-photo">
                        <img src={currentPhoto} alt={title}/>
                        <div className="item-photos-mobile">
                            { ItemThumbs(image) }
                        </div>
                    </Col>
                    <Col sm="6">
                        <div className="item-detail">
                            <span className="item-title">
                                <b>{title}</b>
                                <Badge className="item-badge" variant={variant}>{variants_name[sub_type]}</Badge><br/>
                                {parse(description)}
                                <br/><br/>
                                { duration ? <b><FontAwesomeIcon icon={faClock} /> {duration}</b> : ''}
                                <br/><br/>
                                {includes ? <span><em><b>Incluye:</b></em><br/></span> : ''}
                                {includes ? parse(includes) : ''}<br/>
                            </span>
                            <Price mxn={mxn} usd={usd} />
                            { mxn > 0 || usd > 0 ?
                                <Button onClick={() => setModalShow(true)}><FontAwesomeIcon icon={faShoppingCart} /> Comprar</Button> :
                                ''
                            }
                            <div className="item-photos">
                                { ItemThumbs(image) }
                            </div>
                        </div>
                    </Col>
                </Row>
                <ModalItem show={modalShow}
                    onHide={() => setModalShow(false)}
                    title={title}
                    mxn={mxn}
                    usd={usd}
                    paypal={paypal}
                    />
            </Col>
        </Row>
    );
}

export default Item;