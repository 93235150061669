import { React, Component } from 'react';
import WOW from 'wowjs';
import { Helmet } from 'react-helmet';

import './promoted.scss';


//images
import background from './images/bg.jpg';
import AboutMe from './images/aboutme.jpg';
import Demo from './images/demo.jpeg';
import FbOgImg from './images/fbogimg.jpg';

class Promoted extends Component {
	constructor() {
		super();
		this.state = {
			city: ''
		}
	}
	componentDidMount() {
        new WOW.WOW({live: false}).init();
    }

    renderBackground = () => {
        return <div className="slide" style={{opacity: '1', background: `url("${background}") 60% 80% no-repeat`}}></div>
    }

	renderData(city) {
		this.renderDate(city);
		this.renderInclude(city);
		this.renderTopics(city);
	}

	renderDate() {
		switch(this.state.city) {
			case 'HMO':
				return '12 y 13 de Febrero del 2022';
			case 'CDMX':
				return '5 y 6 de Febrero del 2022';
			case 'TIJ':
				return  '19 y 20 de Marzo del 2022';
			default:
				return '';
				
		}
	}

	renderInclude() {
		switch(this.state.city) {
			case 'HMO':
				return '12 y 13 de Febrero del 2022';
			case 'CDMX':
				return '5 y 6 de Febrero del 2022';
			case 'TIJ':
				return '19 y 20 de Marzo del 2022';
			default:
				return '';
		}
	}

	renderTopics(city) {
		switch(this.state.city) {
			case 'HMO':
				return(
					<div>
						<h1 style={{textAlign: 'center'}}>¿Qué incluye?</h1>
						<p>
							- PDF redactado a detalle con los puntos trabajados durante el taller.<br/>
							- Clase grupal en línea para aclarar las dudas que puedan surgir en los dias posteriores al taller (ésta se impartirá 2 semanas posteriores al taller, por medio de zoom).<br/>
							- 1 set de actions con los pasos trabajados durante el taller.
							- Incluye comida el Sábado 12 de febrero.
						</p>
						<div className="demoImg" style={{textAlign: 'center'}}>
							<img style={{padding: '10px', borderRadius: '3px'}} src={Demo} width="600px" alt="Girl with a hat on christmas" />
						</div>
						<h1 style={{textAlign: 'center'}}>12 de Febrero</h1>
						<ul>
							<li>- Introducción: El Retrato (30 minutos)</li>
							<li className="division">10:45am - 1:00pm</li>
							<li>
								<h4>- Práctica #1: Calidad de luz, tamaño y distancia.</h4>
								<p>
									Los participantes tendrán la oportunidad de poder entender de una manera práctica
									con ayuda de ejercicios de intensidad de la luz (luz dura y luz suave), asi como 
									jugar con los distintos tamaños de las fuentes de luz. Podrán asimilar el uso de la luz
									de una manera práctica y dinámica.
								</p>
							</li>
							<li>
								<h4>- Práctica #2: La luz como herramienta creativa.</h4>
								<p>
									Realizaremos ejercicios que nos ayuden a comprender que la dirección de la luz dependerá 
									de los resultados que busquemos en nuestras imagenes.
								</p>
							</li>
							<li className="division">Comida 1:00pm - 3:30pm</li>
							<li className="division">3:30pm - 7:00pm</li>
							<li>- Practica con modelo 1</li>
							<li>- Practica con modelo 2</li>
							<li>- Practica con modelo 3</li>
						</ul>
						<h1 style={{textAlign: 'center'}}>13 de Febrero</h1>
						<p>Te compartiré mi flujo de trabajo para lograr un acabado estilo Fine Art</p>
						<ul>
							<li className="division">Edición (10:00am - 1:00pm)</li>
							<li>Pasos manuales en cámara raw y Photoshop</li>
							<li>- Selección de mi galería</li>
							<li>- Revelado del raw</li>
							<li>- Separación de frecuencias</li>
							<li>- Color</li>
							<li>- Dodge and burn</li>
							<li>- Volumen</li>
							<li>- Ambientación</li>
							<li>- Exportación</li>
							<li>- Impresión</li>
							<li className="division">Comida (1:00pm - 2:00pm)</li>
							<li className="division">Edición Pt. 2 (2:00pm - 3:00pm)</li>
							<li>Trabajaremos con el uso de acciones para agilizar tiempos de post-producción</li>
						</ul>
						<h1 style={{textAlign: 'center'}}><b>Costo: $8,500.00 MXN</b></h1>
						<p>Reserva con el 30% de anticipo</p>
					</div>
				);
			case 'CDMX':
				return(
					<div>
						<h1 style={{textAlign: 'center'}}>¿Qué incluye?</h1>
						<p>
							- PDF redactado a detalle con los puntos trabajados durante el taller.<br/>
							- Clase grupal en línea para aclarar las dudas que puedan surgir en los dias posteriores al taller (ésta se impartirá 2 semanas posteriores al taller, por medio de zoom).<br/>
							- 1 set de actions con los pasos trabajados durante el taller.
							- Incluye comida el Sábado 5 de febrero.
						</p>
						<div className="demoImg" style={{textAlign: 'center'}}>
							<img style={{padding: '10px', borderRadius: '3px'}} src={Demo} width="600px" alt="Girl with a hat on christmas" />
						</div>
						<h1 style={{textAlign: 'center'}}>5 de Febrero</h1>
						<ul>
							<li>- Introducción: El Retrato (30 minutos)</li>
							<li className="division">10:45am - 1:00pm</li>
							<li>
								<h4>- Práctica #1: Calidad de luz, tamaño y distancia.</h4>
								<p>
									Los participantes tendrán la oportunidad de poder entender de una manera práctica
									con ayuda de ejercicios de intensidad de la luz (luz dura y luz suave), asi como 
									jugar con los distintos tamaños de las fuentes de luz. Podrán asimilar el uso de la luz
									de una manera práctica y dinámica.
								</p>
							</li>
							<li>
								<h4>- Práctica #2: La luz como herramienta creativa.</h4>
								<p>
									Realizaremos ejercicios que nos ayuden a comprender que la dirección de la luz dependerá 
									de los resultados que busquemos en nuestras imagenes.
								</p>
							</li>
							<li className="division">Comida 1:00pm - 3:30pm</li>
							<li className="division">3:30pm - 7:00pm</li>
							<li>- Practica con modelo 1</li>
							<li>- Practica con modelo 2</li>
							<li>- Practica con modelo 3</li>
						</ul>
						<h1 style={{textAlign: 'center'}}>6 de Febrero</h1>
						<p>Te compartiré mi flujo de trabajo para lograr un acabado estilo Fine Art</p>
						<ul>
							<li className="division">Edición (10:00am - 1:00pm)</li>
							<li>Pasos manuales en cámara raw y Photoshop</li>
							<li>- Selección de mi galería</li>
							<li>- Revelado del raw</li>
							<li>- Separación de frecuencias</li>
							<li>- Color</li>
							<li>- Dodge and burn</li>
							<li>- Volumen</li>
							<li>- Ambientación</li>
							<li>- Exportación</li>
							<li>- Impresión</li>
							<li className="division">Comida (1:00pm - 2:00pm)</li>
							<li className="division">Edición Pt. 2 (2:00pm - 3:00pm)</li>
							<li>Trabajaremos con el uso de acciones para agilizar tiempos de post-producción</li>
						</ul>
						<h1 style={{textAlign: 'center'}}><b>Costo: $8,500.00 MXN</b></h1>
						<p>Reserva con el 30% de anticipo</p>
					</div>
				);
			case 'TIJ':
				return(
					<div>
						<h1 style={{textAlign: 'center'}}>¿Qué incluye?</h1>
						<p>
							- PDF redactado a detalle con los puntos trabajados durante el taller.<br/>
							- Clase grupal en línea para aclarar las dudas que puedan surgir en los dias posteriores al taller (ésta se impartirá 2 semanas posteriores al taller, por medio de zoom).<br/>
							- 1 set de actions con los pasos trabajados durante el taller.
							- Incluye comida el Sábado 19 de Marzo.
						</p>
						<div className="demoImg" style={{textAlign: 'center'}}>
							<img style={{padding: '10px', borderRadius: '3px'}} src={Demo} width="600px" alt="Girl with a hat on christmas" />
						</div>
						<h1 style={{textAlign: 'center'}}>19 de Marzo</h1>
						<ul>
							<li>- Introducción: El Retrato (30 minutos)</li>
							<li className="division">10:45am - 1:00pm</li>
							<li>
								<h4>- Práctica #1: Calidad de luz, tamaño y distancia.</h4>
								<p>
									Los participantes tendrán la oportunidad de poder entender de una manera práctica
									con ayuda de ejercicios de intensidad de la luz (luz dura y luz suave), asi como 
									jugar con los distintos tamaños de las fuentes de luz. Podrán asimilar el uso de la luz
									de una manera práctica y dinámica.
								</p>
							</li>
							<li>
								<h4>- Práctica #2: La luz como herramienta creativa.</h4>
								<p>
									Realizaremos ejercicios que nos ayuden a comprender que la dirección de la luz dependerá 
									de los resultados que busquemos en nuestras imagenes.
								</p>
							</li>
							<li className="division">Comida 1:00pm - 2:00pm</li>
							<li className="division">2:00pm - 5:00pm</li>
							<li>- Practica con modelo 1</li>
							<li>- Practica con modelo 2</li>
							<li>- Practica con modelo 3</li>
						</ul>
						<h1 style={{textAlign: 'center'}}>20 de Marzo</h1>
						<p>Te compartiré mi flujo de trabajo para lograr un acabado estilo Fine Art</p>
						<ul>
							<li className="division">Edición (10:00am - 1:00pm)</li>
							<li>Pasos manuales en cámara raw y Photoshop</li>
							<li>- Selección de mi galería</li>
							<li>- Revelado del raw</li>
							<li>- Separación de frecuencias</li>
							<li>- Color</li>
							<li>- Dodge and burn</li>
							<li>- Volumen</li>
							<li>- Ambientación</li>
							<li>- Exportación</li>
							<li>- Impresión</li>
							<li className="division">Comida (1:00pm - 2:00pm)</li>
							<li className="division">Edición Pt. 2 (2:00pm - 3:00pm)</li>
							<li>Trabajaremos con el uso de acciones para agilizar tiempos de post-producción</li>
						</ul>
						<h1 style={{textAlign: 'center'}}><b>Costo: $8,500.00 MXN</b></h1>
						<p>Reserva con el 30% de anticipo</p>
					</div>
				);
			default:
				return '';		
		}
	}

    render() {
        return(
			<div className="content-fete">
				<Helmet>
					<meta property="og:description" content="Hola! Mi nombre es Nathalia Gutierrez, te quiero invitar a este workshop que he preparado para ti con mucho cariño. Me encataría compartirte mi estilo de trabajo, mis esquemas de iluminación y mis técnicas de edición, pero mas que nada lograr inspirarte para que puedas poner en práctica lo aprendido con tu propio sello personal y con la magia que hay en ti!" />
					<meta property="og:url" content="https://www.nathaliagutierrez.com.mx/courses/la-fete-du-cirque" />
					<meta property="og:image" content={FbOgImg} />
					<meta property="og:title" content="Nathalia Gutierrez - Curso: Lâ Fete Du Cirque" />
				</Helmet>
				<div className="background">
					<div className="fete-content">
						<div className="first">
							<h1 className="wow bounceInUp"
								data-wow-delay="0.5s"
								data-wow-duration="1s">Workshop</h1>
							<h2 className="wow bounceInUp"
								data-wow-delay="0.8s"
								data-wow-duration="1s">La Fête Du Cirque</h2>
							<p className="wow bounceInUp"
								data-wow-delay="0.8s"
								data-wow-duration="1s">Retrato Creativo</p>
						</div>
						<div className="second">
							<img src={AboutMe} style={{ bordeRadius: '3px' }} alt="Nathalia Gutierrez"/>
							<div className="description">
								<p>Hola! Mi nombre es Nathalia Gutierrez, te quiero invitar a este workshop que he preparado para ti con mucho cariño.</p>
								<p>Me encataría compartirte mi estilo de trabajo, mis esquemas de iluminación y mis técnicas de edición, pero mas que nada lograr inspirarte para que puedas poner en práctica lo aprendido con tu propio sello personal y con la magia que hay en ti!</p>
							</div>
						</div>
						<div className="third container">
							<h1>Elige tu ciudad</h1>
							<div className="options">
								<button onClick={() => this.setState({city: 'HMO'}) }>Hermosillo</button>
								<button onClick={() => this.setState({city: 'CDMX'}) }>CDMX</button>
								<button onClick={() => this.setState({city: 'TIJ'}) }>Tijuana</button>
							</div>
							<div className="dates">
								{this.renderDate()}
							</div>
							<div className="topics">
								{this.renderTopics()}
							</div>
						</div>
					</div>
				</div>
			</div>
        );
    }
}

export default Promoted;
