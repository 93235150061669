import React, { Component } from 'react';
import './slideshow.scss';

//images
import slide1 from './images/slide-1.jpg';
import slide2 from './images/slide-2.jpg';
import slide3 from './images/slide-3.jpg';
import slide4 from './images/slide-4.jpg';

class Slideshow extends Component {
    constructor() {
        super();
        this.state = {
            imagesList: [
                {style: {opacity: '1', background: `url("${slide1}") 60% 80% no-repeat`}},
                {style: {opacity: '0', background: `url("${slide2}") 50% center no-repeat`}},
                {style: {opacity: '0', background: `url("${slide3}") center center no-repeat`}},
                {style: {opacity: '0', background: `url("${slide4}") 30% center no-repeat`}},
            ]
        }
    }

    changeImage = () => {
        let random = Math.floor(Math.random() * 4);
        
        let newImagesList = [
            {style: {opacity: '0', background: `url("${slide1}") 60% 80% no-repeat`}},
            {style: {opacity: '0', background: `url("${slide2}") 50% center no-repeat`}},
            {style: {opacity: '0', background: `url("${slide3}") center center no-repeat`}},
            {style: {opacity: '0', background: `url("${slide4}") 30% center no-repeat`}},
        ];

        newImagesList[random].style.opacity = '1';
        
        this.setState({imagesList: newImagesList });
    }

    renderSlides = () => {
        return this.state.imagesList.map((img, index) => {
            return <div className="slide" key={index} style={img.style}></div>
        });
    }

    componentDidMount() {
        this.slideInterval = setInterval(() => {
            this.changeImage();
        }, 4000)
    }

    componentWillUnmount() {
        clearInterval(this.slideInterval);
    }

    render() {
        return(
            <div className="image-slider">
                {this.renderSlides()}
            </div>
        );
    }
}

export default Slideshow;