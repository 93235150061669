import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import WOW from 'wowjs';
import Slideshow from './slideshow';
import AboutMePhoto from './images/aboutme.jpg';
import ParaFotografos from './images/para_fotografos.jpeg';
import Fotografia from './images/fotografia.jpeg';
import LaFeteCirque from './images/demo.jpeg';
import WorkshopRetratoInfantil from './images/workshop_1.jpg';
import './home.scss';
import Divider from '../common/divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

class Home extends Component {
    componentDidMount() {
        new WOW.WOW({live: false}).init();
    }
    render(){
        return(
            <div className="content-home">
                <Slideshow />
                <Container>
                    <section className="section pb-0">
                        <span className="wow fadeIn title text-center" 
                            data-wow-delay="0.1s"
                            data-wow-duration="1s">Acerca de Mí</span>
                        <Row>
                            <Col lg="7" md="6">
                                <p className="about-text wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">Mi nombre es Nathalia Gutiérrez Zamora, y me dedico a crear mágicas historias dentro de imágenes, congelando momentos únicos.</p>
                                <p className="about-text wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">La Fotografía nos permite sacar la magia que hay dentro de nosotros.</p>
                            </Col>
                            <Col lg="5" md="6">
                                <img className="about-photo shadow wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" alt="AboutMe" src={AboutMePhoto} />
                            </Col>
                        </Row>
                    </section>
                    <Divider />
                    <section className="section pb-0">
                        <span className="wow fadeIn title text-center" 
                            data-wow-delay="0.1s"
                            data-wow-duration="1s">Workshop Retrato Infantil</span>
                        <Row>
                            <Col lg="7" md="6">
                                <p className="about-text wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                                En éste curso te compartiré técnicas que te ayudarán a poder expresarte creativamente através de tu trabajo. Quiero compartirte mi proceso de post-producción de principio a fin.
                                </p>
                                <h1 className="about-text text-center">14 y 15 de Enero 2023</h1>
                                <p className="about-text text-center wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                                <Link to="/courses/retrato-infantil">
                                    <Button className="wow fadeIn pt-10" variant="info">
                                        <FontAwesomeIcon icon={faInfoCircle} /> Ver Más información
                                    </Button>
                                </Link>
                                </p>
                            </Col>
                            <Col lg="5" md="6">
                                <img className="about-photo shadow wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" alt="AboutMe" src={WorkshopRetratoInfantil} />
                            </Col>
                        </Row>
                    </section>
                    <Divider />
                    <section className="section pt-0">
                        <h2 className="title wow fadeIn pt-0 text-center">Conóce más</h2>
                        <h6 className="wow text-center fadeIn pt-0 mobile-label">Click para ver más</h6>
                        <Row className="wow fadeIn">
                            <Col lg="6" md="6">
                                <Col lg="12" md="12" className="photography p-0">
                                    <Link to="/gallery">
                                        <img className="products-photo shadow" alt="Fotografía" src={Fotografia} />
                                        <div className="overlay">Fotografía</div>
                                    </Link>
                                </Col>
                            </Col>
                            <Col lg="6" md="6">
                                <Col lg="12" md="12" className="workshops p-0">
                                    <Link to="/courses">
                                        <img className="products-photo shadow" alt="Para Fotografos" src={ParaFotografos} />
                                        <div className="overlay">Cursos</div>
                                    </Link>
                                </Col>
                            </Col>
                        </Row>
                    </section>
                    <Divider />
                    <section className="section pt-0">
                        <Row>
                            <Col className="text-center p-5">
                                <h3 className="title wow fadeIn pt-0">¿Necesitas más información?</h3>
                                <Link to="/contact"><Button className="wow fadeIn pt-10" variant="info">¡Da click aquí!</Button></Link>
                            </Col>
                        </Row>
                    </section>
                </Container>
                {/* <Row className="stripe">
                    <Col lg="3" md="3" sm="3" className="m-0 p-0">
                        <img className="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" alt="AboutMe" src={stripePhoto1} />
                    </Col>
                    <Col lg="3" md="3" sm="3" className="m-0 p-0">
                        <img className="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" alt="AboutMe" src={stripePhoto2} />
                    </Col>
                    <Col lg="3" md="3" sm="3" className="m-0 p-0">
                        <img className="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" alt="AboutMe" src={stripePhoto3} />
                    </Col>
                    <Col lg="3" md="3" sm="3" className="m-0 p-0">
                        <img className="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" alt="AboutMe" src={stripePhoto4} />
                    </Col>
                </Row> */}
            </div>
        );
    }
}

export default Home;