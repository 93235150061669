import { React, Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { API_URL } from '../../constants';
import WOW from 'wowjs';
import axios from 'axios';

import './courses.scss';
import ProductCard from '../common/productCard';

class Courses extends Component {
	constructor() {
		super();
		this.state = {
			showing: 'all',
			products: []
		}
	}

	componentDidMount() {
        new WOW.WOW({live: false}).init();
		this.fetchCourses();
    }

	filter = (term) => {
		if(term !== this.state.showing){
			this.setState({showing: term})
		}
	}

	fetchCourses = () => {
		console.log(API_URL);
		axios.get(API_URL+'/product?type=course')
		.then(({ data }) => this.setState({products: data.data}))
	}

	render() {
		console.log(this.state.products);
		return (
			<Container className="content">
				<Row>
					<Col className="text-center">
						<h1 className="wow fadeIn title flex-left">Cursos</h1>
					</Col>
				</Row>
				<Row className="filters wow fadeIn">
					<Col>
						<div className={this.state.showing === 'all' ? "button selected" : "button"} onClick={() => this.filter('all')}>Todo</div>
						<div className={this.state.showing === 'recorded' ? "button selected" : "button"} onClick={() => this.filter('recorded')}>Pre-grabados</div>
						<div className={this.state.showing === 'online' ? "button selected" : "button"} onClick={() => this.filter('online')}>En línea</div>
						<div className={this.state.showing === 'on-site' ? "button selected" : "button"} onClick={() => this.filter('on-site')}>Presencial</div>
					</Col>
				</Row>
				<ProductCard products={this.state.products} showing={this.state.showing} />
			</Container>
		);
	}
}

export default Courses;
