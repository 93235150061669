import { React, Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { API_URL } from '../../constants';
import WOW from 'wowjs';
import axios from 'axios';

import './tutorials.scss';
import ProductCard from '../common/productCard';

class Tutorials extends Component {
	constructor() {
		super();
		this.state = {
			showing: 'all'
		}
	}

	componentDidMount() {
        new WOW.WOW({live: false}).init();
		this.fetchCourses();
    }

	filter = (term) => {
		if(term !== this.state.showing){
			this.setState({showing: term})
		}
	}

	fetchCourses = () => {
		axios.get(API_URL+'/product?type=resource')
		.then(({ data }) => this.setState({products: data.data}))
	}

	render() {
		return (
			<Container className="content">
				<Row>
					<Col className="text-center">
						<h1 className="wow fadeIn title flex-left">Tutoriales y Recursos</h1>
					</Col>
				</Row>
				<Row className="filters wow fadeIn">
					<Col>
						<div className={this.state.showing === 'all' ? "button selected" : "button"} onClick={() => this.filter('all')}>Todo</div>
						<div className={this.state.showing === 'tutorial' ? "button selected" : "button"} onClick={() => this.filter('tutorial')}>Tutoriales</div>
						<div className={this.state.showing === 'digital-resource' ? "button selected" : "button"} onClick={() => this.filter('digital-resource')}>Recursos Digitales</div>
						<div className={this.state.showing === 'action' ? "button selected" : "button"} onClick={() => this.filter('action')}>Actions</div>
					</Col>
				</Row>
				<ProductCard products={this.state.products} showing={this.state.showing} />
			</Container>
		);
	}
}

export default Tutorials;
