import React from 'react';
import Item from '../item';

const ProductCard = ({ products, showing }) => {
    if(!products) return <React.Fragment>Cargando productos...</React.Fragment>;

    return products.map(product => {
        let {
            id, images, duration,
            includes, name, price_mx,
            price_usd, sub_type, type, description,
            paypal
        } = product;

        
        let imagesArray = cleanImagesData(images);

        return <Item
                    key={id} 
                    identifier={id} 
                    showing={showing} 
                    title={name} 
                    mxn={price_mx}
                    usd={price_usd}
                    type={type}
                    sub_type={sub_type}
                    includes={includes}
                    image={imagesArray}
                    duration={duration}
                    description={description}
                    paypal={paypal} />
    });
}

const cleanImagesData = (data) => {
    let regex = new RegExp('api-nathalia-gutierrez.test', 'g')
    let tempFix = data.replace(regex, 'api.nathaliagutierrez.com.mx')
    return JSON.parse(tempFix);
}

export default ProductCard;