import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { SRLWrapper } from "simple-react-lightbox";
import { API_URL } from '../../constants';

function Gallery() {
	let [ gallery, setGallery ] = useState([]);

	const options = {
		buttons: {
			backgroundColor: "rgba(255, 255, 255, 0.8)",
			iconColor: "rgba(255, 255, 255, 0.7)",
			showDownloadButton: false
		},
		settings: {
			overlayColor: "rgba(0, 0, 0, 0.8)",
			transitionSpeed: 1000,
			transitionTimingFunction: "linear",
		},
		thumbnails: {
			showThumbnails: false,
			thumbnailsSize: ["120px", "100px"],
			thumbnailsOpacity: 0.4
		},
		caption: {
			captionColor: "rgba(255, 255, 255, 1)"
		},
		progressBar: {
			size: "4px",
			backgroundColor: "rgba(255, 255, 225, 1)",
			fillColor: "#AF9AB2"
		}
	};

	const getGalleryItems = () => {
		axios.get(API_URL+'/gallery').then(({ data }) => setGallery(data.data));
	}
	
	useEffect(() => {
		getGalleryItems();
	}, []);

	const GalleryItems = () => {
		return gallery.map((item) => {
			return (
				<div className="col-md-4 col-6 col-image-with-link wow fadeIn" key={item.id}>
					<a href={item.image}>
						<img src={item.thumbnail} alt="Nathalia Gutierrez Fotografía" srl_gallery_image="true"/>
					</a>
				</div>
			);
		});
	}

	return (
		<Container className="content-home">
			<SRLWrapper options={options}>
				<div id="gallery-with-links" className="content">
					<div className="row paquete">
						<h1 className="title wow fadeIn text-center">Paquetes de Fotografía</h1>
						<div className="pricing wow fadeIn">
							<div className="pricing-header">
								Paquete 1
							</div>
							<img src="/gallery/paquetes/foto-1.jpg" alt="Paquete 1" />
							<ul className="pricing-details">
								<li>45 minutos de sesión</li>
								<li>8 imágenes digitales</li>
								<li>Edición profesional</li>
								<li>1 collage digital</li>
								<li>Vestuario incluido</li>
								<li className="price">$3,500.00 MXN</li>
							</ul>
						</div>
						<div className="pricing wow fadeIn">
							<div className="pricing-header">
								Paquete 2
							</div>
							<img src="/gallery/paquetes/foto-2.jpg" alt="Paquete 2"/>
							<ul className="pricing-details">
								<li>1 hora de sesión</li>
								<li>13 imágenes digitales</li>
								<li>Edición profesional</li>
								<li>1 collage digital</li>
								<li>Vestuario incluido</li>
								<li className="price">$5,000.00 MXN</li>
							</ul>
						</div>
						<div className="pricing wow fadeIn">
							<div className="pricing-header">
								Paquete 3
							</div>
							<img src="/gallery/paquetes/foto-3.jpg" alt="Paquete 3"/>
							<ul className="pricing-details">
								<li>1 hora de sesión</li>
								<li>18 imágenes digitales</li>
								<li>Edición profesional</li>
								<li>1 collage digital</li>
								<li>1 tarjeta digital</li>
								<li>Vestuario incluido</li>
								<li className="price">$6,300.00 MXN</li>
							</ul>
						</div>
						<span className="disclaimer">Costo por imágen digital extra $490.00 MXN</span>
					</div>
					<div className="row paquete maternity">
						<h1 className="title wow fadeIn text-center">Paquetes de Maternidad</h1>
						<div className="pricing wow fadeIn">
							<div className="pricing-header">
								Paquete 1
							</div>
							<img src="/gallery/paquetes/maternity-1.jpg" alt="Paquete 1" />
							<ul className="pricing-details">
								<li>45 minutos de sesión</li>
								<li>8 imágenes digitales</li>
								<li>2 vestuarios</li>
								<li className="price">$4,000.00 MXN</li>
							</ul>
						</div>
						<div className="pricing wow fadeIn">
							<div className="pricing-header">
								Paquete 2
							</div>
							<img src="/gallery/paquetes/maternity-2.jpg" alt="Paquete 2"/>
							<ul className="pricing-details">
								<li>1 hora de sesión</li>
								<li>13 imágenes digitales</li>
								<li>4 vestuarios</li>
								<li className="price">$5,500.00 MXN</li>
							</ul>
						</div>
						<div className="pricing wow fadeIn">
							<div className="pricing-header">
								Paquete 3
							</div>
							<img src="/gallery/paquetes/maternity-3.jpg" alt="Paquete 3"/>
							<ul className="pricing-details">
								<li>1 hora de sesión</li>
								<li>18 imágenes digitales</li>
								<li>6 vestuarios</li>
								<li className="price">$6,800.00 MXN</li>
							</ul>
						</div>
						<span className="disclaimer">Costo por imágen digital extra $500.00 MXN</span>
					</div>
					<div className="row gallery">
						<h1 className="title wow fadeIn text-center">Galería</h1>
						<GalleryItems />
					</div>
				</div>
			</SRLWrapper>
		</Container>
	);
};

export default Gallery;
