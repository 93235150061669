import './divider.scss';

const Divider = ({ className }) => {
    return(
        <div className={`divider text-center ${className}`}>
            <img src="/logos/logo-square-black.png" alt="Nathalia Logo" height="50px"/>
        </div>
    );
};

export default Divider;