import { React, Component } from 'react';
import WOW from 'wowjs';
import { Helmet } from 'react-helmet';

import './promoted.scss';


//images
import background from './images/bg-fineart.jpg';
import FirstImg from './images/fineart.jpg';
import SecondImg from './images/fineart-2.jpg';
import FbOgImg from './images/fbogimg.jpg';

class Promoted2 extends Component {
	constructor() {
		super();
		this.state = {
			city: ''
		}
	}
	componentDidMount() {
        new WOW.WOW({live: false}).init();
    }

    renderBackground = () => {
        return <div className="slide" style={{opacity: '1', background: `url("${background}") 60% 80% no-repeat`}}></div>
    }

    render() {
        return(
			<div className="content-fete">
				<Helmet>
					<meta property="og:description" content="En éste curso te compartiré técnicas que te ayudarán a poder expresarte creativamente através de tu trabajo." />
					<meta property="og:url" content="https://www.nathaliagutierrez.com.mx/courses/la-fete-du-cirque" />
					<meta property="og:image" content={FbOgImg} />
					<meta property="og:title" content="Nathalia Gutierrez - Workshop Fine Art" />
				</Helmet>
				<div className="background">
					<div className="fete-content">
						<div className="first">
							<h1 className="wow bounceInUp gold"
								data-wow-delay="0.5s"
								data-wow-duration="1s">Workshop</h1>
							<p className="wow bounceInUp dark-gold"
								data-wow-delay="0.8s"
								data-wow-duration="1s">Fine Art</p>
							<h2 className="wow bounceInUp gold"
								data-wow-delay="0.8s"
								data-wow-duration="1s">19 y 20 de Febrero de 2022</h2>
						</div>
						<div className="second dark-gold justify-center">
							<div className="description align-self-center max-w-600">
								<p>En éste curso te compartiré técnicas que te ayudarán a poder expresarte creativamente através de tu trabajo.</p>
							</div>
							<img src={FirstImg} style={{ bordeRadius: '3px' }} alt="Workshop Fine Art"/>
						</div>
						<div className="third container flex flex-row justify-center">
							<div className="demoImg align-self-center" style={{textAlign: 'center'}}>
								<img style={{padding: '10px', borderRadius: '3px'}} src={SecondImg} width="480px" alt="Girl with a hat on christmas" />
							</div>
							<div className="flex flex-column align-self-center max-w-400">
								<h1 className="gold" style={{textAlign: 'center'}}>¿Qué incluye?</h1>
								<p className="dark-gold" style={{fontSize: '2.0rem'}}>
									- Manual PDF con toda la información vista en el curso.<br/>
									- Ambas clases serán grabadas y enviadas por Drive<br/>
									- 1 set de actions para Photoshop.
									- Incluye recursos digitales.
								</p>
							</div>
						</div>
						<div className="third container flex flex-column justify-center">
							<div className="flex flex-column align-self-center max-w-400">
								<h1 className="gold" style={{textAlign: 'center'}}>Primer Día</h1>
								<p className="dark-gold" style={{fontSize: '2.0rem'}}>
									- Herramientas prácticas para planear tu sesión<br/>
									- El color como herramienta de composición<br/>
									- Edición estilo Fine Art (Primera parte) - Uso de acciones y manuales
								</p>
							</div>
							<div className="flex flex-column align-self-center max-w-400">
								<h1 className="gold" style={{textAlign: 'center'}}>Segundo Día</h1>
								<p className="dark-gold" style={{fontSize: '2.0rem'}}>
									- Edición estilo Fine Art (Segunda Parte)<br/>
									Uso de acciones y pasos manuales en Photoshop<br/>
								</p>
							</div>
						</div>
						<div className="third container flex flex-column align-self-center justify-center">
							<div className="dates flex flex-row align-self-center justify-center">
								<div className="p-20">
									<h1 className="gold" style={{textAlign: 'center'}}>19 de Febrero</h1>
									<h2>12:00pm - 3:00pm</h2>
								</div>
								<div className="p-20">
									<h1 className="gold" style={{textAlign: 'center'}}>20 de Febrero</h1>
									<h2>12:00pm - 3:00pm</h2>
								</div>
							</div>
							<h2>Horario de Hermosillo, Sonora</h2>
							<div className="dates flex flex-row align-self-center justify-center">
								<div className="p-20">
									<h1 className="gold" style={{textAlign: 'center'}}>Precio Normal</h1>
									<h2>$4,600.00</h2>
								</div>
								<div className="p-20">
									<h1 className="gold" style={{textAlign: 'center'}}>Estudiantes</h1>
									<h2>$4,000.00</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        );
    }
}

export default Promoted2;
