import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import './header.scss';

const Header = () => {

    const [ fixed, setFixed ] = useState(false);
    const [ mobileNavShow, setMobileNavShow ] = useState(false);
    const [ mobileNavIcon, setMobileNavIcon ] = useState(faChevronDown);
    
    const location = useLocation();
    let headerStyle = [];
    let mobileNavStyle = ["site-menu"];
    let logoStyle = ["logo-container"];

    if(fixed || location.pathname !== '/'){
        headerStyle.push('scrolled');
        logoStyle.push('square');
    }

    if(mobileNavShow) {
        mobileNavStyle.push("show");
    }else{
        mobileNavStyle = ["site-menu"];
    }

    let history = useHistory();

    useEffect(() => {
        history.listen((action) => {
            setMobileNavShow(false);
            setMobileNavIcon(faChevronDown);
        });
    });

    const handleNavShow = () => {
        if(mobileNavShow) {
            setMobileNavShow(false);
            setMobileNavIcon(faChevronDown);
        }else{
            setMobileNavIcon(faChevronUp);
            setMobileNavShow(true);
        }
    }

    const handleScroll = () => {
        if(location.pathname !== '/') return;
        const offset = window.scrollY;

        if(offset > 220){
            setFixed(true);
        }else{
            setFixed(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    });

    return(
        <header className={headerStyle.join(" ")}>
            <Link to="/">
                <div className={logoStyle.join(" ")}></div>
            </Link>
            <div className={mobileNavStyle.join(" ")}>
                <ul>
                    <li>
                        <Link to="/">Inicio</Link>
                    </li>
                    <li className={location.pathname === '/gallery' ? 'active' : '' }>
                        <Link to="/gallery">Fotografía</Link>
                    </li>
                    <li className={location.pathname === '/courses' ? 'active' : '' }>
                        <Link to="/courses">Cursos</Link>
                    </li>
                    <li className={location.pathname === '/tutorials' ? 'active' : '' }>
                        <Link to="/tutorials">Recursos</Link>
                    </li>
                    <li className={location.pathname === '/contact' ? 'active' : '' }>
                        <Link to="/contact">Contacto</Link>
                    </li>
                </ul>
            </div>
            <div className="mobile-nav" onClick={handleNavShow}>
                <FontAwesomeIcon icon={mobileNavIcon} />
            </div>
        </header>
    );
}

export default Header;