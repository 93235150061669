import { React, Component } from 'react';
import WOW from 'wowjs';
import { Helmet } from 'react-helmet';

import './retrato-infantil.scss';


//images
import background from './images/bg.jpg';
import SecondImg from './images/workshop_2.jpg';
import FifthImg from './images/workshop_5.jpg';
import SixthImg from './images/workshop_6.jpg';
import FbOgImg from './images/fbogimg.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb, Col, Container, Row } from 'react-bootstrap';

class RetratoInfantil extends Component {
	constructor() {
		super();
		this.state = {
			city: ''
		}
	}
	componentDidMount() {
        new WOW.WOW({live: false}).init();
    }

    renderBackground = () => {
        return <div className="slide" style={{opacity: '1', background: `url("${background}") 60% 80% no-repeat`}}></div>
    }

    render() {
		const today = new Date();
        return(
			<div className="product-container">
				<div className="content-retrato">
					<Helmet>
						<meta property="og:description" content="En éste curso te compartiré técnicas que te ayudarán a poder expresarte creativamente através de tu trabajo." />
						<meta property="og:url" content="https://www.nathaliagutierrez.com.mx/courses/la-fete-du-cirque" />
						<meta property="og:image" content={FbOgImg} />
						<meta property="og:title" content="Nathalia Gutierrez - Workshop Fine Art" />
					</Helmet>
					<div className="background-retrato">
						<div className="fete-content">
							<div className="first">
								<h1 className="wow bounceInUp gold"
									data-wow-delay="0.5s"
									data-wow-duration="1s">Workshop</h1>
								<p className="wow bounceInUp dark-gold"
									data-wow-delay="0.8s"
									data-wow-duration="1s">Retrato Infantil</p>
								<h2 className="wow bounceInUp gold"
									data-wow-delay="0.8s"
									data-wow-duration="1s">14 y 15 de Enero de 2023</h2>
							</div>
						</div>
					</div>
				</div>
				<Container>
					<Row className="justify-center product" style={{ overflowX: 'hidden' }}>
						<Col xs={8} sm={8} md={6} className="wow fadeInLeft" data-wow-delay="0.5s">
							<img src={SecondImg} alt="Nathalia Gutierrez Fotografía" />
						</Col>
						<Col xs={12} md={6} sm={12} className="description wow fadeInLeft" data-wow-delay="0.5s">
							<Breadcrumb><span><FontAwesomeIcon icon={faHome} /> » Cursos » Retrato Infantil</span></Breadcrumb>
							<h1>Retrato Infantil</h1>
							<p>
								La inspiración es de suma importancia para lograr transmitir en nuestras fotos emociones, 
								contar una historia a través de imágenes que hablen de quienes somos, de nuestra visión 
								como fotógrafos. Es por eso que quiero compartirte la manera cómo yo hago mi proceso creativo. 
								Esto también te ayudará a encontrar tu propio estilo.
							</p>
							<h6>¿Qué incluye?</h6>
							<p>
								- Set de acciones con todo mi flujo de trabajo.<br/>
								- Video de la edición.<br/>
								- PDF con los pasos manuales escritos.<br/>
								- PDF con la parte teorica del workshop.<br/>
							</p>
							<h6>Costo: $900 USD/$800 USD hasta el 5 de Diciembre del 2022</h6>
							<h6>Puedes reservar con $300 USD</h6>
							<em>*3 días antes del workshop te enviaremos la solicitud de PayPal para liquida el resto.</em>
							<div className="flex flex-row justify-center buy-now">
								<div className="flex flex-column p-5">
									<h1>Reservar</h1>
									<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
										<input type="hidden" name="cmd" value="_s-xclick"/>
										<input type="hidden" name="hosted_button_id" value="EF6YSJQZHZT98"/>
										<table>
											<tr><td><input type="hidden" name="on0" value="Nombre"/>Nombre</td></tr><tr><td><input type="text" name="os0" maxLength="200" required/></td></tr>
											<tr><td><input type="hidden" name="on1" value="Correo Electrónico"/>Correo Electrónico</td></tr><tr><td><input type="text" name="os1" maxLength="200" required/></td></tr>
										</table>
										<input type="image" src="https://www.paypalobjects.com/es_XC/MX/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal, la forma más segura y rápida de pagar en línea."/>
										<img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1"/>
									</form>
								</div>
								<div className="flex flex-column p-5">
									<h1>Comprar</h1>
									{
										today < new Date('2022-12-05') ?
										<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
											<input type="hidden" name="cmd" value="_s-xclick" />
											<input type="hidden" name="hosted_button_id" value="6B4UUU4ZAHMJA" />
											<table>
												<tr><td><input type="hidden" name="on0" value="Nombre" />Nombre</td></tr><tr><td><input type="text" name="os0" maxLength="200" required /></td></tr>
												<tr><td><input type="hidden" name="on1" value="Correo Electrónico"/>Correo Electrónico</td></tr><tr><td><input type="text" name="os1" maxLength="200" required /></td></tr>
											</table>
											<input type="image" src="https://www.paypalobjects.com/es_XC/MX/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal, la forma más segura y rápida de pagar en línea."/>
											<img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1"/>
										</form>
										:
										<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
											<input type="hidden" name="cmd" value="_s-xclick"/>
											<input type="hidden" name="hosted_button_id" value="A8H3XCQHBX22E"/>
											<table>
											<tr><td><input type="hidden" name="on0" value="Nombre"/>Nombre</td></tr><tr><td><input type="text" name="os0" maxLength="200"/></td></tr>
											<tr><td><input type="hidden" name="on1" value="Correo Electrónico"/>Correo Electrónico</td></tr><tr><td><input type="text" name="os1" maxLength="200"/></td></tr>
											</table>
											<input type="image" src="https://www.paypalobjects.com/es_XC/MX/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal, la forma más segura y rápida de pagar en línea."/>
											<img alt="" border="0" src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif" width="1" height="1"/>
										</form>
									}
								</div>
							</div>
						</Col>
					</Row>
					<div className="divider"></div>
					<Row className="justify-center product" style={{ overflowX: 'hidden' }}>
						<Col xs={12} md={6} sm={12} className="description wow fadeInRight" data-wow-delay="0.5s">
							<h1>¿Cómo debo prepararme para el workshop?</h1>
							<p>
								- Lleva tu cámara &#x1F4F8; y un lente adecuado para retratos, recomiendo entre <b>50mm</b> y <b>135mm</b>.<br/>
								- Lleva una libreta &#x1F4D3; y pluma &#x1F58A; para hacer anotaciones.<br/>
								- Una computadora portátil &#x1F4BB; si deseas editar durante el taller.<br/>
								- Si tienes alguna duda antes del taller estaré feliz de poder ayudarte.
							</p>
						</Col>
						<Col xs={8} sm={8} md={4} className="wow fadeInRight" data-wow-delay="0.5s">
							<img src={FifthImg} alt="Nathalia Gutierrez Fotografía" />
						</Col>
					</Row>
					<div className="divider"></div>
					<Row className="justify-center product" style={{ overflowX: 'hidden' }}>
						<Col xs={8} sm={6} md={7} className="wow fadeInLeft" data-wow-delay="0.5s">
							<img src={SixthImg} alt="Nathalia Gutierrez Fotografía" />
						</Col>
						<Col xs={12} md={5} sm={12} className="description wow fadeInLeft" data-wow-delay="0.5s">
							<h2>Itinerario</h2>
							<h2>Día 1</h2>
							<p>
								<b>10:00am</b> - Inicio<br/>
								- Etapas de creación de un concepto usando el moodboard como herramienta creativa.<br/>
								- Papel del vestuario, accesorios y peinado para darle fuerza a la historia.<br/>
								- El color, como elemento de composicón.<br/>
							</p>
							<Row>
								<Col>
									<p>
										<b>Iluminación</b><br/>
										- Luz dura y luz suave<br/>
										- Caída de Luz<br/>
										- Equipo de iluminación<br/>
										- Perfilado de luces<br/>
										- Dirección de luz<br/>
										- Luz principal<br/>
										- Luz de relleno<br/>
									</p>
								</Col>
								<Col>
									<p>
										- Luz de fondo<br/>
										- Luz de contar<br/>
										- Construyendo tu esquema de iluminación<br/>
										- Esquemas de iluminación<br/>
									</p>
								</Col>
							</Row>
							<p>
								<b>12:30pm</b> - Práctica con modelo #1<br/>
								De principio podrás verme trabajar con cada modelo para poder aterrizar lo visto durante la parte teórica. 
								Para trabajar con orden y mayor aprovechamiento durante las prácticas, se dividirá el grupo en dos, un grupo 
								trabajará con la primer modelo y el otro con la segunda.<br/>
								<em>*Se trabajará con 3 diferentes esquemas de luz durante las prácticas.</em>
							</p>
							<p>
								<b>2:00pm</b> - Almuerzo<br/>
								<b>4:00pm</b> - Práctica con modelo #2<br/>
								Podrás verme trabajar con cada modelo de manera teórica como en la actividad anterior. Durante las prácticas 
								trabajaremos con algunas estrategias para que los niños se sientan mas cómodos y logremos obtener poses y gestos que 
								aporten emoción a nuestras fotos.
								<em>*Se trabajará con 3 diferentes esquemas de luz.</em>
							</p>
							<h2>Día 2</h2>
							<p>
								<b>10:00am</b> - Iniciamos
								- Selección de imágenes en Adobe LR.<br/>
								- Elegir las mejores tomas y discutir brevemente.<br/>
								- Post-producción en cámara RAW y Adobe PS.<br/><br/>
							</p>
							<h5>La post-producción se divide en 2 partes:</h5>
							<p>
								<b>Parte 1:</b> Me verán editar una imágen con pasos manuales y una mas con acciones.<br/>
								<b>Parte 2:</b> Trabajarás de manera individual con tu propia fotografía, con mi vigilancia.<br/><br/>
								<b>5:30pm - Resumen y conclusión</b>
							</p>
						</Col>
					</Row>
					<div className="divider"></div>
					<Row className="justify-center product" style={{ overflowX: 'hidden' }}>
						<Col xs={12} md={6} sm={12} className="description wow fadeInRight" data-wow-delay="0.5s">
							<h2>Ubicación</h2>
							<p>
								Estudio de <a href="https://www.instagram.com/CCMerazPhotography/" target="_blank" rel="noreferrer">@CCMerazPhotography</a><br/>
								C&C Meraz Photography
								17332 Irvine Blvd Suite 105<br/>
								Tustin, CA 92780<br/>
								United States
							</p>
						</Col>
						<Col xs={8} sm={8} md={6} className="wow fadeInRight" data-wow-delay="0.5s">
							<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3317.4923131020028!2d-117.83211568435745!3d33.747941880689865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcdc30976c1853%3A0x564fcffcee0aae60!2sC%26C%20Meraz%20Photography!5e0!3m2!1sen!2smx!4v1666850316551!5m2!1sen!2smx" width="100%" height="400px" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
						</Col>
					</Row>
				</Container>
			</div>
        );
    }
}

export default RetratoInfantil;